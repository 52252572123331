import {MDBSpinner} from "mdbreact";
import React from "react";
import configApp from "../config.json";

export default function CenteredLoader() {

    return(
        <div style={{
            backgroundColor : "rgb(" + configApp.backgroundColorR + ", " + configApp.backgroundColorG + ", " + configApp.backgroundColorB + ")",
            width : "100vw",
            height : "100vh",
            display : "flex",
            justifyContent : "center",
            alignItems : "center"
        }}>
            <MDBSpinner/>
        </div>
    );
}
