import firebase from 'firebase';
import secret_client from './config/secret-client.json'

try {
    firebase.initializeApp(secret_client.firebase_client[process.env.NODE_ENV]);
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
} catch(err){
    if (!/already exists/.test(err.message)) {
        console.error("Firebase error", err.message);
    }
}
const firebaseConnector = firebase;
export default firebaseConnector;
