import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {MDBSpinner} from "mdbreact";

import firebaseConnector from "../api/firebase-connector";
import DisplaySound from "../components/Display/DisplaySound";
import {getStorageUrl} from "../App";
import DisplayVideo from "../components/Display/DisplayVideo";
import DisplayMarkdown from "../components/Display/DisplayMarkdown";
import CenteredLoader from "../components/CenteredLoader";

export default function Display() {

    let history = useHistory();

    const [isLoading, setIsLoading] = useState(true)
    const [stand, setStand] = useState(undefined)
    const [baseURL, setBaseURL] = useState("")

    let { language, id } = useParams();

    useEffect(()=>{

        const prepare = async () => {

            let resConfig = await firebaseConnector.firestore().collection("Configs").doc("storage-config").get();

            setBaseURL(resConfig.data().baseUrl);

            let res = await firebaseConnector.firestore().collection("Stands").where("nfcId", "==", id).get();
            if(!res.empty){

                let myStand = res.docs[0].data();
                myStand.image = await getStorageUrl(myStand.image);

                if(myStand.type === "sound" || myStand.type === "video") {
                    myStand.resFile.filter(file => file.langCode === language)[0].file = await getStorageUrl(myStand.resFile.filter(file => file.langCode === language)[0].file);
                }

                setStand(myStand);
            }else{
                history.push("/" + language + "/list")
            }

            setIsLoading(false)
        }

        prepare()
    }, [])

    if(isLoading)
        return <CenteredLoader/>

    return(
        <div>
            {
                stand.type === "sound" ? (
                    <DisplaySound
                        backgroundImageLink={stand.image}
                        audioLink={stand.resFile.filter(file => file.langCode === language)[0].file}
                        name={stand.resFile.filter(file => file.langCode === language)[0].text}
                    />
                ) : stand.type === "video" ? (
                    <DisplayVideo
                        backgroundImageLink={stand.image}
                        videoLink={stand.resFile.filter(file => file.langCode === language)[0].file}
                        name={stand.resFile.filter(file => file.langCode === language)[0].text}
                        optionnalText={id === "01010101" ? "Le canard mandarin (Aix galericulata) est une espèce de canards appartenant à la famille des anatidés, originaire d'Asie du Nord-Est. Il peut cependant être observé à l'état sauvage en Europe où quelques individus échappés de captivité se sont acclimatés et s'y reproduisent aujourd'hui régulièrement." : null}
                    />
                ) : stand.type === "markdown" ? (
                    <DisplayMarkdown
                        backgroundImageLink={stand.image}
                        markdown={stand.resFile.filter(file => file.langCode === language)[0].file}
                        text={stand.resFile.filter(file => file.langCode === language)[0].text}
                        baseUrl={baseURL}
                    />
                ) : (<p>Error</p>)
            }
        </div>
    );
}
