import React, {useEffect, useState} from 'react';
import {Route, Switch } from 'react-router-dom';
import {MDBSpinner} from "mdbreact";

import './assets/css/app.css';

import Display from "./pages/Display";
import List from "./pages/List";
import firebaseConnector from "./api/firebase-connector";
import CenteredLoader from "./components/CenteredLoader";

export default function App() {
    const [isLoading, setIsLoading] = useState(true)

    useEffect(()=>{
        setIsLoading(false)
    }, [])

    if(isLoading)
        return <CenteredLoader/>

    return(
        <Switch>
            <Route path='/:language/list' exact component={List}/>
            <Route path='/:language/:id' exact component={Display}/>
        </Switch>
    );
}

export async function getStorageUrl(firestorePath) {
    if (firestorePath === "") {
        return "";
    }
    let refStorageConfig = firebaseConnector.firestore().collection("Configs").doc("storage-config");
    let res = await refStorageConfig.get()
    let prefix = "";

    if(res.exists){
        prefix = res.data().baseUrl;
    }

    return prefix + firestorePath;
}
