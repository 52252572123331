import {Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import configApp from "../../config.json";

export default function DisplayVideo({backgroundImageLink, videoLink, name, optionnalText}) {

    const useStyles = makeStyles(() => ({
        root : {
            padding : "0px",
            backgroundColor : "rgb(" + configApp.backgroundColorR + ", " + configApp.backgroundColorG + ", " + configApp.backgroundColorB + ")",
            width : "100vw",
            minHeight : "100vh",
            "& ::-webkit-scrollbar" : {
                display: "none"
            }
        },
        imgContainer : {
            height : "50vh",
            backgroundImage : "linear-gradient(rgba(" + configApp.primaryColorR + ", " + configApp.primaryColorG + ", " + configApp.primaryColorB + ", 0.7), rgba(" + configApp.primaryColorR + ", " + configApp.primaryColorG + ", " + configApp.primaryColorB + ", 0.7)), url(" + backgroundImageLink + ")",
            backgroundSize : "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition : "center"
        },
        name : {
            fontSize : "2vmin",
            textAlign : "center",
            color : "rgba(" + configApp.primaryColorR + ", " + configApp.primaryColorG + ", " + configApp.primaryColorB + ")",
            padding : "35px 0px 15px 0px"
        }
    }))
    const classes = useStyles();

    return(
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={12} className={classes.imgContainer}/>

                <Grid item xs={12} className={classes.name}>
                    <h1>{name}</h1>
                </Grid>

                <Grid item xs={1}/>
                <Grid item xs={10}>
                    <video controls style={{width : "100%"}}>
                        <source src={videoLink} type="video/mp4"/>
                        <p>
                            Votre navigateur ne prend pas en charge l'audio. Voici un
                            un <a href={videoLink}>lien vers le fichier audio</a>
                        </p>
                    </video>
                    <br/>
                    <br/>
                    {
                        optionnalText ? optionnalText : ""
                    }
                </Grid>
                <Grid item xs={1}/>
            </Grid>
        </div>
    );
}
