import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

import '@fortawesome/fontawesome-pro/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css'

import './index.css';

import configApp from "./config.json"

import App from './App';

const theme = createMuiTheme({
    palette: {
        primary: {
            contrastText: "white",
            main: "#0f3f62",
            light: "#f7fafc"
        },
    },
})

ReactDOM.render(
    <BrowserRouter>
        <ThemeProvider theme={theme}>
            <App/>
        </ThemeProvider>
    </BrowserRouter>,
    document.getElementById('root')
);
