import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {MDBSpinner} from "mdbreact";

import CardStand from "../components/List/CardStand";

import firebaseConnector from "../api/firebase-connector";
import {makeStyles} from "@material-ui/styles";
import configApp from "../config.json";
import {getStorageUrl} from "../App";
import CenteredLoader from "../components/CenteredLoader";

export default function List() {

    const [stands, setStands] = useState([])

    const [isLoading, setIsLoading] = useState(true)

    let { language } = useParams();

    const useStyles = makeStyles((theme) => ({
        cardContainer : {
            padding : "25px 65px",
            backgroundColor : "rgb(" + configApp.backgroundColorR + ", " + configApp.backgroundColorG + ", " + configApp.backgroundColorB + ")",
            width : "100vw",
            minHeight : "100vh",
            "& ::-webkit-scrollbar" : {
                display: "none"
            }
        }
    }))
    const classes = useStyles();

    useEffect(()=>{
        const prepare = async () => {
            const res = await firebaseConnector.firestore().collection("Stands").get()
            if( !res.empty ){

                let standArray = await Promise.all(res.docs.map(async (doc) => {
                    let myData = doc.data();

                    myData.image = await getStorageUrl(myData.image);

                    return myData;
                }))

                console.log(standArray);

                setStands([...standArray]);
            }

            setIsLoading(false)
        }
        prepare()
    }, [])

    if(isLoading)
        return <CenteredLoader/>

    return(
        <div className={classes.cardContainer}>
            {
                stands.map((stand, index) => (
                        <CardStand
                            name={stand.resFile.filter(file => file.langCode === language)[0].name}
                            backgroundImageLink={stand.image}
                            language={language}
                            nfcId={stand.nfcId}
                            key={index}
                        />
                    )
                )
            }
        </div>
    );
}
