import {Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import configApp from "../../config.json";

export default function DisplayMarkdown({backgroundImageLink, markdown, text, baseUrl}) {

    const useStyles = makeStyles((theme) => ({
        root : {
            backgroundColor : "rgb(" + configApp.backgroundColorR + ", " + configApp.backgroundColorG + ", " + configApp.backgroundColorB + ")",
            width : "100vw",
            minHeight : "100vh",
            padding : "0px",
            "& ::-webkit-scrollbar" : {
                display: "none"
            }
        },
        markdownImageContainer : {
            width : "100%",
            height : "150px",
            padding : "0px",
            backgroundPosition : "center",
            backgroundSize : "contain",
            backgroundRepeat : "no-repeat"
        },
        markdownYoutubeIFrameContainer : {
            width : "100%",
            display : "flex",
            justifyContent : "center",
            alignItems : "center",
        },
        markdownYoutubeIFrame : {
            width : "560px",
            height : "315px"
        },
        markdownImage : {
            maxWidth : "50%",
            height : "100%"
        },
        markdownText : {
            color : "#797687"
        },
        mardownTitle : {
            color : theme.palette.text.main,
            fontWeight : "500",
            width : "100%",
            textAlign : "center"
        },
        imgContainer : {
            height : "50vh",
            backgroundImage : "linear-gradient(rgba(" + configApp.primaryColorR + ", " + configApp.primaryColorG + ", " + configApp.primaryColorB + ", 0.7), rgba(" + configApp.primaryColorR + ", " + configApp.primaryColorG + ", " + configApp.primaryColorB + ", 0.7)), url(" + backgroundImageLink + ")",
            backgroundSize : "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition : "center"
        },
        name : {
            fontSize : "2vmin",
            textAlign : "center",
            color : "rgba(" + configApp.primaryColorR + ", " + configApp.primaryColorG + ", " + configApp.primaryColorB + ")",
            padding : "35px 0px 35px 0px"
        }
    }));
    const classes = useStyles();

    return(
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={12} className={classes.imgContainer}/>

                <Grid item xs={12} className={classes.name}>
                    <h1>{text}</h1>
                </Grid>

                <Grid item xs={1}/>

                <Grid item xs={10}>
                    {
                        markdown !== null && markdown !== undefined && markdown.split("\n").map((ligne, index) => {
                            if(ligne.slice(0,2) === "# "){
                                return (
                                    <h4 key={index} className={classes.mardownTitle}>
                                        {ligne.slice(2)}
                                    </h4>
                                )
                            }else if(ligne.slice(0,2) === "[[" && ligne.slice(-2) === "]]"){
                                var url = ligne.slice(2).slice(0, -2)
                                if(url.slice(0,24) === "https://www.youtube.com/" || url.slice(0,17) === "https://youtu.be/"){
                                    if(url.slice(0,30) === "https://www.youtube.com/embed/"){

                                    }else if(url.slice(0,32) === "https://www.youtube.com/watch?v="){
                                        url = url.replace("watch?v=", "embed/")
                                    }else if(url.slice(0,17) === "https://youtu.be/"){
                                        url = url.replace("https://youtu.be/", "https://www.youtube.com/embed/")
                                    }
                                    return (
                                        <div className={classes.markdownYoutubeIFrameContainer}>
                                            <iframe
                                                className={classes.markdownYoutubeIFrame}
                                                src={url}
                                                title="YouTube video player"
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen
                                            />
                                        </div>
                                    )
                                }else if(url.slice(0,4) !== "http"){
                                    //Construction de l'url
                                    url = baseUrl + url
                                }
                                return (
                                    <div className={classes.markdownImageContainer} style={{backgroundImage : "url(" + url + ")"}}/>
                                )

                            }else{
                                return (
                                    <p className={classes.markdownText}>
                                        {ligne}
                                    </p>
                                )
                            }
                        })
                    }
                </Grid>
                <Grid item xs={1}/>
            </Grid>
        </div>
    )
}
