import React, {useEffect} from 'react';

import configApp from "../../config.json"

import '../../assets/css/app.css';
import {makeStyles} from "@material-ui/styles";
import {useHistory} from "react-router-dom";

export default function CardStand({backgroundImageLink, name, nfcId, language}) {

    let history = useHistory();

    const useStyles = makeStyles(() => ({
        card : {
            padding : "20px",
            marginBottom : "20px",
            borderRadius : "25px",
            backgroundImage : "linear-gradient(rgba(" + configApp.primaryColorR + ", " + configApp.primaryColorG + ", " + configApp.primaryColorB + ", 0.7), rgba(" + configApp.primaryColorR + ", " + configApp.primaryColorG + ", " + configApp.primaryColorB + ", 0.7)), url(" + backgroundImageLink + ")",
            backgroundSize : "cover",
            backgroundPosition : "center",
            cursor : "pointer"
        },
        cardTitle : {
            margin : "35px 5px",
            fontSize : "150%",
            color : "white",
            textAlign : "center",
            fontWeight : 400
        }
    }))
    const classes = useStyles();


    useEffect(()=>{

    }, [])

    return(
        <div className={classes.card} onClick={() => history.push("/" + language + "/" + nfcId)}>
            <p className={classes.cardTitle}>{name}</p>
        </div>
    );
}
